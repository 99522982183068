import React from "react";
import '../../styles/semantic.min.css';
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import GreetingsNipd from "../../components/about-us/GreetingsNipd";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import PageHeader from "../../components/utils/PageHeader";
import {Container, Grid, Segment} from "semantic-ui-react";
import AboutUsNavigation from "../../components/navigation/AboutUsNavigation";
import DesktopContainer from "../../container/DesktopContainer";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import layout from "../../templates/layout.module.css";

export default () =>
    <LayoutContainer>
        <SEO title={'Grußwort NipD'}/>


            <MobileTabletContainer>
                <MainNavigation/>
                <PageHeader title={'Grußwort'}
                            subHeader={'Geleitwort des ersten Vorsitzenden des Netzwerkes interdisziplinäre pädiatrische Dermatologie e. V.'}/>
                <MobileBgWrapper>
                    <Container>
                        <Segment>
                    <GreetingsNipd/>
                        </Segment>
                    </Container>
                </MobileBgWrapper>
                <Footer/>
            </MobileTabletContainer>
            <DesktopContainer>
                <div className={layout.pageWrapper}>
                <MainNavigation/>
                <Container fluid>
                <PageHeader title={'Grußwort'}
                            subHeader={'Geleitwort des ersten Vorsitzenden des Netzwerkes interdisziplinäre pädiatrische Dermatologie e. V.'}/>
                <Grid centered columns={2}>
                    <Grid.Column width={11}>
                            <GreetingsNipd/>
                        <AboutUsNavigation/>
                    </Grid.Column>
                    <Grid.Column width={4}/>
                </Grid>
                </Container>
                <Footer/>
                </div>
            </DesktopContainer>

    </LayoutContainer>