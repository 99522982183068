import React from 'react';
import {Image, Segment,} from 'semantic-ui-react';
import profileImg1 from "../../assets/cremer.jpg";
import ddg from "../../assets/nipd.png";
import componentStyles from "../../templates/default.module.css";

const GreetingsNipd = () => (
    <Segment>
        <Image src={ddg} floated={'right'} size={'small'} className={componentStyles.greetingsImg}/>
        <Image src={profileImg1} floated={'left'} size={'medium'} className={componentStyles.greetingsImg}/>
        <p>Sehr geehrte Damen,</p>
        <p>sehr geehrte Herren,</p>
        <p>die Gründung der Stiftung Kinderdermatologie bedeutet in meinen Augen einen wichtigen Schritt auf dem Wege einer Verbesserung der Versorgung von Kindern mit Erkrankungen im Bereich der Haut und seiner Anhangsgebilde.</p>
        <p>Warum liegt bisher hier einiges im Argen?</p>
        <p>Kinderärzte verstehen viel von Kindern, aber wenig von der Haut, Hautärzte verstehen viel von der Haut, aber wenig von Kindern – dabei bestätigen Ausnahmen natürlich die Regel.
            Das liegt daran, dass Kinderärzte während ihrer Facharztausbildung keine Ausbildung in Dermatologie erhalten, obwohl sie später in der Praxis in ca. 25% der Patienten kontakte mit Hauterkrankungen bei Kindern zu tun haben. Umgekehrt erhalten auch Hautärzte keine Ausbildung in Kinderheilkunde.</p>
        <p>Andere Länder haben dieses Problem längst erkannt, so gibt es USA, Italien, England und Frankreich u.a. das Spezialgebiet „pädiatrische Dermatologie“, wobei die jeweiligen Fachgruppen je ein Jahr Ausbildung im anderen Fachbereich nachweisen müssen.</p>
        <p>Das von Kinderärzten und Dermatologen gemeinsam gegründete „Netzwerk interdisziplinäre pädiatrische Dermatologie e.V.“ versucht hier einen Brückenschlag zwischen Pädiatern und Dermatologen. Die Brücke taucht als illustratives Element auf der Website des NipD immer wieder auf, sie steht als Bindeglied zwischen den beiden (mehr oder weniger) fremden Ufern Kinderheilkunde und Dermatologie. Das NipD hat sich den wissenschaftlichen und informativen Austausch zwischen beiden Facharztgruppen zum Ziel gesetzt. Wir wollen die Brücke bilden, auf der sich beide Fachgruppen treffen und austauschen können.</p>
        <p>Es ist wichtig, dass die Deutsche Stiftung Kinderdermatologie die gemeinsamen Zwecke der forschungsorientierten Einrichtungen der Arbeitsgemeinschaft Pädiatrische Dermatologie in der Deutschen Dermatologischen Gesellschaft und der praxisorientierten Einrichtung im Netzwerk interdisziplinäre pädiatrische Dermatologie (NipD) vereint. Die Deutsche Stiftung Kinderdermatologie hat damit eine weitere Brücke gebaut zwischen beiden Disziplinen zum Wohl der uns anvertrauten Kinder. </p>
        <p>Daher wünsche ich als 1. Vorsitzender der NipD der Deutschen Stiftung Kinderdermatologie alles Gute zu ihrer Gründung und ihrer weiteren erfolgreichen Stiftungsarbeit.</p>
        <p>Mit freundlichen Grüßen</p>
        <p>Prof. Dr. med. Hansjörg Cremer</p>
    </Segment>

);

export default GreetingsNipd;